import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/copyrightRegistration/HomeView.vue'
import Index from '../views/index.vue'
// import introduce from '../views/introduce.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '/',
        name: 'introduce',
        component: () => import('../views/introduce/introduceNew.vue'),
        // component: () => import('../views/introduce/introduce.vue'),
        meta: {
          shovalues: true
        }
      },
      // {
      //   path: '/home',
      //   name: 'home',
      //   component: HomeView
      // },
      {
        path: '/copyright',
        component: () => import('../views/copyrightRegistration/indexNew.vue'),
        children: [{
          path: 'home',
          component: () => import('../views/copyrightRegistration/HomeViewNew.vue'),
        },{
          path: 'preservation',
          component: () => import('../views/preservaTion/HomeViewNew.vue'),
        },{
          path: 'password',
          component: () => import('../views/copyrightRegistration/passwordNew.vue'),
        },{
          path: 'government',
          component: () => import('../views/copyrightRegistration/government.vue'),
        },{
          path: 'preservaTion',
          component: () => import('../views/preservaTion/index.vue'),
        }]
      },
      {
        path: '/copyrightRegistration',
        name: 'copyrightRegistration',
        component: () => import('../views/copyrightRegistration/AboutView.vue')
      },
      {
        path: '/preservaRegistration',
        name: 'preservaRegistration',
        component: () => import('../views/preservaTion/AboutView.vue')
      },
      {
        path: '/registerList',
        name: 'registerList',
        component: () => import('../views/register/registerList.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../views/register/register.vue')
      },
      {
        path: '/registerGoods',
        name: 'registerGoods',
        component: () => import('../views/register/registerGoods.vue')
      },
      {
        path: '/transferenceList',
        name: 'transferenceList',
        component: () => import('../views/transference/transferenceList.vue')
      },
      {
        path: '/transference',
        name: 'transference',
        component: () => import('../views/transference/transference.vue')
      },
      {
        path: '/transferenceGoods',
        name: 'transferenceGoods',
        component: () => import('../views/transference/transferenceGoods.vue')
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/login.vue'),
        meta: {
          shovalues: true
        }
      },
      {
        path: '/newsList',
        name: 'newsList',
        component: () => import('../views/introduce/newsList.vue'),
        meta: { shovalues: true }
      },
      {
        path: '/information',
        name: 'information',
        component: () => import('../views/introduce/information.vue'),
        meta: { shovalues: true }
      },
      {
        path: '/logon',
        name: 'logon',
        component: () => import('../views/login/register.vue'),
        meta: {
          shovalues: true
        }
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('../views/myAccount/index.vue')
      },
      {
        path: '/addCard',
        name: 'addCard',
        component: () => import('../views/myAccount/addCard/index.vue')
      },
      {
        path: '/document',
        name: 'document',
        // component: () => import('../views/preservaTion/introduce.vue'),
        component: () => import('../views//myAccount/document.vue'),
        meta: {
          shovalues: true
        }
      },
      {
        path: '/3dModelView',
        name: '3dModelView',
        // component: () => import('../views/preservaTion/introduce.vue'),
        component: () => import('../views/3dModelView/index.vue'),
        meta: {
          shovalues: true
        }
      },
      {
        path: '/exitEvidence',
        name: 'exitEvidence',
        component: () => import('../views/preservaTion/introduceNew.vue'),
        meta: {
          shovalues: true
        }
      },
      {
        path: '/pre-registration',
        name: 'pre-registration',
        component: () => import('../views/copyrightRegistration/introduceNew1.vue'),
        // component: () => import('../views/copyrightRegistration/introduce.vue'),
        meta: {
          shovalues: true
        }
      },
      {
        path: '/copyrightIssues',
        name: 'copyrightIssues',
        component: () => import('../views/copyrightRegistration/copyrightIssues.vue')
      },
      {
        path: '/preservaTion',
        name: 'preservaTion',
        component: () => import('../views/preservaTion/index.vue')
      },
      {
        path: '/certificate',
        name: 'certificate',
        component: () => import('../views/preservaTion/certificate.vue')
      },
      {
        path: '/certificateDetails',
        name: 'certificateDetails',
        component: () => import('../views/preservaTion/details.vue')
      },
      {
        path: '/assetIssuance',
        component: () => import('../views/assetIssuance/index.vue'),
        children: [{
          path: 'assetIssue',
          component: () => import('../views/assetIssuance/assetIssue.vue'),
        }, {
          path: 'newAssetCreate',
          component: () => import('../views/assetIssuance/newAssetCreate.vue'),
        }, {
          path: 'addEdition',
          component: () => import('../views/assetIssuance/addEdition.vue'),
        }, {
          path: 'orderList',
          component: () => import('../views/assetIssuance/orderList/index.vue'),
        }, {
          path: 'shelfList',
          component: () => import('../views/assetIssuance/shelfList/shelfList.vue'),
        }, {
          path: 'shelfDetails',
          component: () => import('@/views/assetIssuance/shelfList/children/shelfDetails')
        }, {
          path: 'assetCommodity',
          component: () => import('@/views/assetIssuance/assetCommodity/index.vue')
        },
        {
          path: 'proDuct',
          component: () => import('@/views/assetIssuance/proDuct/index.vue')
        },
        {
          path: 'products',
          component: () => import('@/views/assetIssuance/proDuct/components/products.vue')
        },
        {
          path: 'proDuctaddEdition',
          component: () => import('@/views/assetIssuance/proDuct/proDuctaddEdition.vue')
        },
      ]
      }, {
        path: '/assetManagement',
        component: () => import('../views/assetManagement/index.vue'),
        children: [{
          path: 'assetsCopyright',
          component: () => import('../views/assetManagement/assetsCopyright.vue'),
        }, {
          path: 'assetsRealRight',
          component: () => import('../views/assetManagement/assetsRealRight.vue'),
        }, {
          path: 'copyrightRegister',
          component: () => import('../views/assetManagement/copyrightRegister.vue'),
        }, {
          path: 'authorizationAssets',
          component: () => import('../views/assetManagement/authorizationAssets.vue'),
        }, {
          path: 'transferAssets',
          component: () => import('../views/assetManagement/transferAssets.vue'),
        }, {
          path: 'workRegistration',
          component: () => import('../views/assetManagement/workRegistration.vue'),
        },
        { //资产管理-资产详情
          path: 'assetsDetail',
          component: () => import('../views/assetManagement/children/assetsDetail.vue'),
        },
        ]
      },{
        path: '/solution',
        component: () => import('../views/solution/index.vue'),
        children: [{
          path: 'arTregistration',
          component: () => import('../views/solution/arTregistration.vue'),
        }, {
          path: 'iteMregistration',
          component: () => import('../views/solution/iteMregistration.vue'),
        },
          {
          path: 'newRealinput',
          component: () => import('../views/solution/newRealinput.vue'),
        },
        {
          path: 'goodsRegister',
          component: () => import('../views/solution/goodsRegister.vue'),
        },
          // {
        //   path: 'shelfList',
        //   component: () => import('../views/assetIssuance/shelfList/shelfList.vue'),
        // }, {
        //   path: 'shelfDetails',
        //   component: () => import('@/views/assetIssuance/shelfList/children/shelfDetails')
        // }, {
        //   path: 'assetCommodity',
        //   component: () => import('@/views/assetIssuance/assetCommodity/index.vue')
        // }
        ]
      },{
        path: '/book',
        component: () => import('../views/book/indxe.vue'),
        children: [{
          path: 'newbookRelease',
          component: () => import('../views/book/newbookRelease.vue'),
        }, {
          path: 'oldbookDistribution',
          component: () => import('../views/book/oldbookDistribution.vue'),
        }, {
          path: 'newAssetCreate',
          component: () => import('../views/book/newAssetCreate.vue'),
        },
        {
          path: 'addEdition',
          component: () => import('../views/book/addEdition.vue'),
        },
          // {
          //   path: 'shelfList',
          //   component: () => import('../views/assetIssuance/shelfList/shelfList.vue'),
          // },
          // {
          //   path: 'shelfDetails',
          //   component: () => import('@/views/assetIssuance/shelfList/children/shelfDetails')
          // },
          // {
          //   path: 'assetCommodity',
          //   component: () => import('@/views/assetIssuance/assetCommodity/index.vue')
          // }
        ]
      },
      {
        path: '/activity',
        component: () => import('../views/activity/indxe.vue'),
        children: [
          {
            path: '/activity/activityList',
            component: () => import('../views/activity/HomeView1.vue'),
          },{
            path: '/activity/activity-create',
            component: () => import('../views/activity/activity-create.vue'),
          },
        ]
      },
      {
        path: '/copyrightDetail',
        name: 'copyrightDetail',
        component: () => import('../views/assetManagement/children/copyrightDetail.vue')
      }, {
        path: '/verification',
        name: 'verification',
        component: () => import('../views/assetManagement/children/verification.vue')
      }, {
        path: '/egistrationDetails',
        name: 'egistrationDetails',
        component: () => import('../views/assetManagement/children/egistrationDetails.vue')
      }, {
        path: '/copyList',
        name: 'copyList',
        component: () => import('../views/assetIssuance/copyList.vue')
      }, {
        path: '/copyDetails',
        name: 'copyDetails',
        component: () => import('../views/assetIssuance/copyDetails.vue')
      }, {
        path: '/nodeAccess',
        name: 'nodeAccess',
        component: () => import('../views/nodeAccess/index.vue'),
        meta: {
          shovalues: true
        }
      },{
        path: '*',
        component: () => import('../views/404.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
