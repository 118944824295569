<template>
  <div class="overall-situation"
    v-loading="fullscreenLoading"
  >
    <div class="situation">
      <div class='tips' v-if='showTip'>
        <div v-if='cardType == 1' class='day'>
          <p>您的{{tip==1?'身份证':'营业执照'}}有效期即将临期（临期30天内不能办理版权登记业务），请<span @click='tochange'>更新{{tip==1?'身份证件':'营业执照'}}</span>后再办理版权登记业务。</p>
          <p class='close' @click='close'></p>
        </div>
        <div v-if='cardType == 2' class='up'>
          <p>您的{{tip==1?'身份证':'营业执照'}}已过有效期，请<span @click='tochange'>更新{{tip==1?'身份证件':'营业执照'}}</span>后再办理版权登记业务。</p>
          <p class='close' @click='close'></p>
        </div>
      </div>
      
      <div class="topBox">
        <span class="topTitle">作品存证</span>
      </div>
      <div class="medium">
        <el-button size="medium" icon="el-icon-plus" @click="registRation">新建存证</el-button>
      </div>
      <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="batchName"
        label="作品名称"
        >
      </el-table-column>
      <el-table-column label="权利取得方式">
        <template slot-scope="scope">
          <span v-if="scope.row.copyrightRightsGet == 0">原始</span>
          <span v-else-if="scope.row.copyrightRightsGet == 1">有偿转让</span>
          <span v-else-if="scope.row.copyrightRightsGet == 2">继承</span>
          <span v-else-if="scope.row.copyrightRightsGet == 3">承受</span>
          <span v-else-if="scope.row.copyrightRightsGet == 4">无偿赠与</span>
          <span v-else-if="scope.row.copyrightRightsGet == 7">法人或者其他组织分立</span>
          <span v-else-if="scope.row.copyrightRightsGet == 8">法人或者其他组织合并</span>
          <span v-else>无</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createTimes"
        label="日期"
        >
      </el-table-column>
      <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <template v-for="(item,index) in examineDate">
              <div class="statesBox" :key="index" v-if="scope.row.examineStatus == item.value">
                <div><i :class="[item.class,'states']"></i>{{item.label}}</div>
              </div>
            </template>
          </template>
        </el-table-column>
      <el-table-column label="操作"  width="230">
        <template slot-scope="scope">
          <el-button type="text"  @click="detailed(scope.row)">
            详情
          </el-button>
          <el-button type="text"  @click="editMessage(scope.row)" 
          v-if="(scope.row.examineStatus == 0 || scope.row.examineStatus == 4) && scope.row.submitUsn == sellusn"
          >
            编辑
          </el-button>
          <el-button type="text"   @click="submitReview(scope.row)"
          v-if="scope.row.examineStatus == 0  && scope.row.submitUsn == sellusn"
          :disable="isLock"
          >
            提交审核
          </el-button>
          <el-button type="text"   @click="viewCertificate(scope.row)"
          v-if="scope.row.examineStatus == 3"
          >
            查看证书
          </el-button>
          <el-button type="text"   @click="deLete(scope.row)"
          v-if="(scope.row.examineStatus == 4 || scope.row.examineStatus == 0) && scope.row.submitUsn == sellusn"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="query.currentPage"
      :page-sizes="[15, 30, 50, 100]"
      :page-size.sync="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
    </div>
    <el-dialog
      title="证书列表"
      :visible.sync="dialogVisible"
      width="55%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      center>
      <el-table :data="list">
        <el-table-column prop="worksName" label="作品名称"></el-table-column>
        <el-table-column label="作品类型">
          <template slot-scope="scope">
            {{workTypeList[scope.row.worksType]}}
          </template>
        </el-table-column>
        <el-table-column label="创作性质">
          <template slot-scope="scope">
            {{typeList[scope.row.worksCreation]||'无'}}
          </template>
        </el-table-column>
        <el-table-column label="创作时间">
          <template slot-scope="scope">
            {{scope.row.worksCreationDate||'无'}}
          </template>
        </el-table-column>
        <el-table-column label="创作地点">
          <template slot-scope="scope">
            {{scope.row.worksCreationCountry}}-{{scope.row.worksCreationCity}}
          </template>
        </el-table-column>
        <el-table-column prop="caSn" label="版权局登记号">
          <template slot-scope="scope">
            {{scope.row.caSn || '暂无'}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button class="btnSpan" type="text" @click="seeBook(scope.row)">查看证书</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChangee"
        @current-change="handleCurrentChangee"
        :current-page.sync="merchandise.currentPage"
        :page-sizes="[15, 30, 50, 100]"
        :page-size.sync="merchandise.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totall">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog class="createBox" :visible.sync="dialogCreate" width="480px">
      <p class='title'>作品是否完成创作？</p>
      <p>请选择您即将新建的存证作品是否已经完成创作</p>
      <el-radio-group v-model="type" style='margin-top: 40px'>
        <el-radio :label="0">未完成</el-radio>
        <el-radio :label="1">完成</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCreate = false">取消</el-button>
        <el-button type="primary" @click="tonext">下一步</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCopyrightApiv1, postSubmit, postrolesDelete, getCopyrightList,postSubmitcopyright, getsfStatus } from "@/api/index";
import moment from 'moment'
export default {
  // name: 'HomeView',
  components: {
    // HelloWorld
  },
  data() {
    return {
      tip: '',
      cardType: false,
      showTip: false,
      dialogCreate: false,
      type: 0,
      sellusn: '',
      file_url:'https://files.wespace.cn',
      // file_url:'http://192.168.1.33',
      fullscreenLoading: false,
      dialogVisible: false,
      tableData: [],//注册列表
      list:[],//证书列表
      examineDate: [
        {
          label: '待提交',
          value: '0',
          class: 'statesSubmit'
        },
        {
          label: '待审核',
          value: '1',
          class: 'statesReviewed'
        },
        {
          label: '审核中',
          value: '2',
          class: 'statesConduct'
        },
        {
          label: '通过',
          value: '3',
          class: 'statesAdopt'
        },
        {
          label: '不通过',
          value: '4',
          class: 'statesFail'
        },
        {
          label: '执行中',
          value: '5',
          class: 'statesConduct'
        },
      ],
      query:{
        currentPage:1,
        pageSize:15,
        status:1,
        usn:''
      },
      codeDisabled: false,
      isLock: false,
      merchandise:{
        currentPage:1,
        pageSize:15,
      },
      total:0,
      totall:0,
      typeList: {
        "0": "原始",
        "1": "转让",
        "2": "继承",
        "3": "承受",
        "4": "赠予",
        "5": "其他"
      },
      workTypeList: {
        1: "文字",
        2: "口述",
        3: "音乐",
        4: "戏剧",
        5: "曲艺",
        6: "舞蹈",
        7: "杂技",
        8: "美术",
        9: "建筑",
        10: "摄影",
        11: "电影",
        12: "类似摄制电影方法创作",
        13: "工程设计图、产品设计图",
        15: "地图、示意图",
        17: "模型",
        19: "录音制品",
        20: "录像制品",
        181: "其他作品"
      },
    }
  },
  mounted() {
    this.sellusn = sessionStorage.getItem('usn')
    this.queryURLParams()
    this.cardType = sessionStorage.getItem('timeFlag') || ''
    this.showTip = this.cardType
    let userData = this.$store.state.userData
    this.tip = userData.postcode
    // let arr = userData.identityCard && userData.identityCard.length && userData.identityCard.filter(val=>(val.checkStatus == 2 && (val.cardType ==1 || val.cardType == 3)))[0]
    // if (arr.expirationDate&&moment(arr.expirationDate) < moment().add(1,'months')) {
    //   this.cardType = true
    // }
  },
  methods:{
    //截取usn
    // 封装函数queryURLParams
    // @params:url
    // @return:参数
    queryURLParams() {
      // let href = 'http://localhost:8081/#/?usn=1ed9aa0184af1cfba659c1a3ee3121a02efde0ffb9066454deaa42ca831b4b7f&order_id=1111111';
      let href = window.location.href;
      // 截取问号后面的query参数
      let query = href.substring(href.indexOf("?") + 1);
      // 以&符号分割
      let item = query.split("&");
      let obj = {};
      for (let i = 0; i < item.length; i++) {
        let arr = item[i].split("=");
        // 参数名，参数值 赋值 对象的属性名，属性值
        obj[arr[0]] = arr[1];
      }
      if(obj.usn == undefined ) {
        this.query.usn = sessionStorage.getItem('usn')
      }else if(obj.usn != '') {
        this.query.usn = obj.usn
        sessionStorage.setItem('usn', obj.usn)
        if(obj.order_id != undefined){
          sessionStorage.setItem('order_id', obj.order_id)
        }
      }
      if(this.query.usn != undefined) {
        this.getList();
      }
      return obj;
    },
    tonext(){
      if (this.type == 0) {
        this.$message('功能暂未开放')
        this.dialogCreate = false
        // this.$router.push({
        //   path: "/certificate",
        // })
      } else if (this.type == 1) {
        let usnInfo = JSON.parse(sessionStorage.getItem('usnInfo'))
        let user = usnInfo.user
        if (user.postcode == 1 && ((user.province && !user.province.includes('北京')) || !user.province)) {
          this.$confirm('非京籍权利人在版权局作品登记需要提交"北京市居住证"信息', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({
              name: 'copyrightRegistration',
            })
          }).catch(()=>{})
        } else {
          this.$router.push({
            name: 'copyrightRegistration',
          })
        }
      }
    },
    close(){
      this.showTip = false
    },
    tochange(){
      this.$router.push('/account')
    },
    //列表
    getList() {
      this.fullscreenLoading = true
      getCopyrightApiv1(this.query).then((response) => {
        if(response.code == 200) {
          this.fullscreenLoading = false
          this.tableData = response.data.list
          this.total = response.data.page.totalRows
        } else {
          this.fullscreenLoading = false
        }
      }).catch(err=>{
        this.fullscreenLoading = false
        console.log(err)
      });
    },
    //新建注册
    registRation() {
      let order_id = sessionStorage.getItem('order_id')
      let usn = sessionStorage.getItem('usn')
      if (!sessionStorage.getItem('usnInfo')) {
        this.$message({
          message: '请先登录',
          type: 'warning'
        })
        return
      }
      let usnInfo = JSON.parse(sessionStorage.getItem('usnInfo'))
      if (!usnInfo || !(usnInfo.user.identityAuthentication == '2')){
        this.$message({
          message: '请先实名认证',
          type: 'warning'
        })
        return
      }
      // let userData = this.$store.state.userData
      // let arr = userData.identityCard && userData.identityCard.length && userData.identityCard.filter(val=>(val.checkStatus == 2 && (val.cardType ==1 || val.cardType == 3)))[0]
      // if (arr.expirationDate&&moment(arr.expirationDate) < moment().add(1,'months')) {
      //   this.$message.error('证件临期，请先更新证件信息')
      //   return false
      // }
      if (this.cardType) {
        this.$message.error(this.cardType==1?'证件临期，请先更新证件信息':'证件已过期，请先更新证件信息')
        return false
      }
      this.dialogCreate = true
      // let user = usnInfo.user
      // if (user.postcode == 1 && ((user.province && !user.province.includes('北京')) || !user.province)) {
      //   this.$confirm('非京籍权利人在版权局作品登记需要提交"北京市居住证"信息', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(() => {
      //     this.$router.push({
      //       name: 'copyrightRegistration',
      //       query: { usn: this.query.usn }
      //     })
      //   }).catch(()=>{})
      // } else {
      //   this.$router.push({
      //     name: 'copyrightRegistration',
      //     query: { usn: this.query.usn }
      //   })
      // }
      // this.$router.push({
      //   name: 'copyrightRegistration',
      //   query: { usn: this.query.usn }
      // })
    },
    //详情
    detailed(row) {
      if(this.query.usn != '' && row.batchNo != '') {
        this.$router.push({
          name: 'copyrightRegistration',
          query: {
            batchNo: row.batchNo,
            showDetails: '1'
          }
        })
      }
    },
    editMessage(row){
      if (this.cardType) {
        this.$message.error(this.cardType==1?'证件临期，请先更新证件信息':'证件已过期，请先更新证件信息')
        return false
      }
      if(this.query.usn != '' && row.batchNo != '') {
        this.$router.push({
          name: 'copyrightRegistration',
          query: {
            batchNo: row.batchNo
          }
        })
      }
    },
    //提交审核
    submitReview(row){
      this.isLock = true
      if (this.cardType) {
        this.$message.error(this.cardType==1?'证件临期，请先更新证件信息':'证件已过期，请先更新证件信息')
        return false
      }
      setTimeout(() => {
        this.fullscreenLoading = true
        let unid = new FormData()
        unid.append('batchNo', row.batchNo)
        unid.append('taskCategory', 1)
        unid.append('orgId', '1394963863358668501')
        unid.append('createUsn', this.query.usn)
        unid.append('createName', '')
        postSubmitcopyright(unid).then( res =>{
          if(res.code == 200) {
            this.$message({
              message: '提交审核成功',
              type: 'success'
            });
            this.getList()
            this.fullscreenLoading = false
            this.isLock = false
          }else{
            this.$message({
              message: res.message,
              type: 'error'
            });
            this.fullscreenLoading = false
            this.isLock = false
          }
        }).catch(err=>{
          this.fullscreenLoading = false
          this.isLock = false
          console.log(err)
        });
        
      }, 1000)
      // let unid = new FormData()
      // unid.append('batchNo', row.batchNo)
      // unid.append('taskCategory', 1)
      // unid.append('orgId', '1394963863358668501')
      // unid.append('createUsn', this.query.usn)
      // unid.append('createName', '')
      // postSubmitcopyright(unid).then( res =>{
      //   if(res.code == 200) {
      //     this.$message({
      //       message: '提交审核成功',
      //       type: 'success'
      //     });
      //     this.getList()
      //   }
      // })
    },
    //查看证书列表
    viewCertificate(row) {
      setTimeout(()=>{
        this.dialogVisible = true
        getCopyrightList({
          batchNo: row.batchNo,
          ...this.merchandise
        }).then((response) => {
          if(response.code == 200) {
            this.fullscreenLoading = false
            this.list = response.data.list
            this.totall = response.data.page.totalRows
          }
        }).catch(err=>{
          this.fullscreenLoading = false
        });
      },1000)

    },
    // 查看通过的作品列表
    seeBook(item) {
      if(item.cerPath){
        window.open(this.file_url + item.cerPath, "_blank");
      }else{
        this.$message({
          message: '证书生成中，请稍后',
          type: 'warning'
        });
      }
    },
    //删除
    deLete(row) {
      if (this.cardType) {
        this.$message.error(this.cardType==1?'证件临期，请先更新证件信息':'证件已过期，请先更新证件信息')
        return false
      }
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let fd = new FormData();
          fd.append("batchNo", row.batchNo);
          postrolesDelete(fd).then((res) => {
            if(res.code == 200) {
              this.getList();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    },

    handleSizeChange(val) {
      this.query.pageSize = val;
      this.getList()
    },
    handleCurrentChange(val) {
      this.query.currentPage = val;
      this.getList()
    },
    handleSizeChangee(val) {
      this.merchandise.pageSize = val;
      // this.getList()
    },
    handleCurrentChangee(val) {
      this.merchandise.currentPage = val;
      // this.getList()
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body{
  padding: 30px 30px 70px;
}
::v-deep .el-dialog__footer{
  padding: 24px 30px;
  border-top: 1px solid #EEEEEE;
}
.createBox{
  font-size: 14px;
  color: #333333;
  .title{
    font-size: 20px;
    color: #181818;
    margin-bottom: 10px;
  }
}
.tips{
  color: #000000;
  line-height: 40px;
  margin-bottom: 20px;
  .day{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    background: url('../../assets/tip.png') rgba(241,195,37,0.1) left 16px center no-repeat;
    background-size: 16px 16px;
    border: 1px solid #F1C325;
    span{
      color: #F1C325;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .up{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
    background: url('../../assets/unpass.png') rgba(255,59,48,0.1) left 16px center no-repeat;
    background-size: 16px 16px;
    border: 1px solid #FF3B30;
    span{
      color: #FF3B30;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .close{
    width: 16px;
    height: 16px;
    background: url('../../assets/close1.png') center center no-repeat;
    background-size: 16px 16px;
    margin-right: 16px;
    cursor: pointer;
  }
}
.topBox{
  padding-bottom: 20px;
  border-bottom: 1px solid #F7F8FA;
  .topTitle{
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 24px;
  }
}
.medium{
  text-align: right;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
// ::v-deep .situation .el-table--fit{
//   margin-left: 30px;
// }
::v-deep .el-pagination{
  text-align: right;
  margin-top: 20px;
}
::v-deep .el-table__header-wrapper{
  background-color: #F7F8FA;

}
::v-deep .el-icon-plus{
  position: relative;
  top: -10px;
  color: #FFFFFF;
  margin-right: 5px;
  margin-left: -5px;
}
::v-deep .medium .el-button--medium{
    width: 102px !important;
    height: 28px !important;
    background: #004AFF !important;
    border: 1px solid #004AFF !important;
    opacity: 1;
    span{
      position: relative;
      top: -10px;
      left: 0px;
      font-size: 12px;
      margin: 0 auto;
      color: #FFFFFF;
      text-align: center;
      line-height: 28px;
      img{
        width: 12px;
        height: 12px;
        // margin-top: 1px;
      }
    }
  }
.overall-situation{
  height: calc(100vh - 160px);
  overflow-y: auto;
  // background: #F7F8FA;
  // min-height: 920px;
  // max-height: 1024px;
  .situation{
    margin: 0 auto;
    min-width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    // min-height: 765px;
    // height: 3825px;
    padding: 20px 20px 20px 20px;
    background: #FFFFFF;
    opacity: 1;
    .Group{
      display: flex;
      span{
        margin-left: 10px;
        // width: 96px;
        // height: 24px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        // line-height: 24px;
      }
    }
  }
  .statesBox {
        display: flex;
        justify-content: center;

    div {
        width: 60px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .states {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
        float: left;
    }

    .statesAdopt {
        //状态-通过
        background: #2DA55D;
    }

    .statesFail {
        //状态-不通过
        background: #E05F42;
    }

    .statesSubmit {
        //状态-待提交
        background: #954EFF;
    }

    .statesReviewed {
        //状态-待审核
        background: #4E77FF;
    }
    .statesConduct{
        //状态-待审核
        background: #FFC300;
    }
}
    
}

</style>
