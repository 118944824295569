import request from "@/utils/request";  //导入封装请求的js文件

//注册列表
export function getCopyrightApi (query) {
  return request({
    url: '/copyrightApi/cr/new/list',
    method: 'get',
    params: query,
  })
}
//注册列表
export function getCopyrightApiv1 (query) {
  return request({
    url: '/copyrightApi/cr/new/list/v1',
    method: 'get',
    params: query,
  })
}

//提交审核
export function postSubmit (data) {
  return request({
    url: '/copyrightApi/copyright/examine/submit',
    method: 'post',
    data: data,
  })
}

//删除注册列表
export function postrolesDelete (data) {
  return request({
    url: '/copyrightApi/cr/new/delete',
    method: 'post',
    data: data,
  })
}

//查看本批次证书列表
export function getCopyrightList (query) {
  return request({
    url: '/copyrightApi/cr/new/cert/list',
    method: 'get',
    params: query,
  })
}
//查看作者/著作权人
export function getQueryPersonalIdentity (data) {
  return request({
    url: 'userApi/user/get_user_details_by_usn',
    method: 'post',
    data: data,
  })
}

//文件效验
export function getFileUploadApi (query) {
  return request({
    url: 'fileUploadApi/ws/file/check/v2',
    method: 'get',
    params: query,
  })
}
//文件上传
export function postUpload (data) {
  return request({
    url: '/fileUploadApi/ws/file/upload',
    method: 'post',
    data: data,
  })
}
//文件合并
export function postMerge (data) {
  return request({
    url: '/fileUploadApi/ws/file/merge',
    method: 'post',
    data: data,
  })
}
//获取详情
export function getDetails (query) {
  return request({
    url: '/copyrightApi/cr/new/details',
    method: 'get',
    params: query,
  })
}

//json保存
export function postSave (data) {
  return request({
    url: 'copyrightApi/cr/new/save',
    method: 'post',
    data: data,
  })
}
//提交审核
export function postSubmitcopyright (data) {
  return request({
    url: '/copyrightApi/copyright/examine/submit',
    method: 'post',
    data: data,
  })
}

//通过证件号搜索信息
export function getUserDetails (data) {
  return request({
    url: '/userApi/user/get_user_details_by_id_card_no',
    method: 'post',
    data: data,
  })
}
//通过证件号搜索信息
export function getUserDetailsV2 (data) {
  return request({
    url: '/userApi/user/get_user_details_by_id_card_no/v2',
    method: 'post',
    data: data,
  })
}

//判断是否可以填写登记
export function getsfStatus (query) {
  return request({
    url: '/copyrightApi/cr/new/status',
    method: 'get',
    params: query,
  })
}
//判断节点状态
export function getsqueryClientByUsn (query) {
  return request({
    url: '/apiAuth/clients/queryClientByUsn',
    method: 'get',
    params: query,
  })
}
//提交节点
export function postSaveOrUpdate (data) {
  return request({
    url: '/apiAuth/clients/saveOrUpdate',
    method: 'post',
    data: data,
  })
}
export function postProblem (data) {
  return request({
    url: '/goodsApi/notice/common/problem',
    method: 'post',
    data: data,
  })
}
export function postProblemv2 (data) {
  return request({
    url: '/goodsApi/notice/common/problem/v2',
    method: 'post',
    data: data,
  })
}
// 查询信息获取验证码
export function getCodeById (data) {
  return request({
    url: '/userApi/user/getIdCardVerificationCode',
    method: 'get',
    params: data,
  })
}
// 查询存证详情
export function getEvidenceDetails (data) {
  return request({
    url: '/copyrightApi/storage/evidence/my/details',
    method: 'get',
    params: data,
  })
}
// 存证列表
export function getEvidenceList (data) {
  return request({
    url: '/copyrightApi/storage/evidence/new/my/list',
    method: 'get',
    params: data,
  })
}
// 存证改版提交/编辑
export function saveEvidence (data) {
  return request({
    url: '/copyrightApi/storage/evidence/new/save',
    method: 'post',
    data: data,
  })
}
// 存证删除
export function deleteEvidence (data) {
  return request({
    url: '/copyrightApi/storage/evidence/new/delete',
    method: 'post',
    data: data,
  })
}
// 存证详情
export function getEvidenceDetailsNew (data) {
  return request({
    url: '/copyrightApi/storage/evidence/new/details',
    method: 'post',
    data: data,
  })
}
// 存证数据采集-未完成状态可以继续新增 Wespace官网提交
export function addHistory (data) {
  return request({
    url: '/copyrightApi/storage/evidence/new/info/collect/addHistory',
    method: 'post',
    data: data,
  })
}
// 存证数据编辑-未完成状态数据编辑
export function updateHistory (data) {
  return request({
    url: '/copyrightApi/storage/evidence/new/info/collect/updateHistory',
    method: 'post',
    data: data,
  })
}
// 存证数据采集-历史数据
export function getHistoryList (data) {
  return request({
    url: '/copyrightApi/storage/evidence/new/my/list/history',
    method: 'get',
    params: data,
  })
}
export function treeWithContentsTitle (data) {
  return request({
    url: '/lawsuitApi/doc/docclassification/treeWithContentsTitle',
    method: 'get',
    params: data,
  })
}
// 存证数据采集-历史数据
export function getHistoryListV2 (data) {
  return request({
    url: '/copyrightApi/storage/evidence/new/my/list/history/v2',
    method: 'get',
    params: data,
  })
}
export function doccontentsList (data) {
  return request({
    url: '/lawsuitApi/doc/doccontents/detail',
    method: 'get',
    params: data,
  })
}
// 完成作品存证撤回
export function recallBanch (data) {
  return request({
    url: '/copyrightApi/cr/new/recall',
    method: 'post',
    data: data,
  })
}
