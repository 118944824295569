import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import './styles/element-variables.scss'
// import 'element-ui/lib/theme-chalk/index.css';
import md5 from 'js-md5'
import store from './store';
import axios from "./js/myAxios"
import globalComponents from './js/globalComponents'
import Paxios from "axios"
Vue.prototype.$axios = Paxios;
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";
Vue.component("mavon-editor", mavonEditor);
import './styles/container.scss'
import '@/styles/index.scss'//公共样式清除
Vue.use(ElementUI);
Vue.use(axios)
Vue.use(globalComponents)

import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

Vue.config.productionTip = false
function rewirteLog () {
  console.log = (function (log) {
    console.log(process.env.NODE_ENV)
    return process.env.NODE_ENV == 'production' ? function () { } : log
  }(console.log))
}
//公共函数处理
import common from "./js/common";  // 1.先引入
Vue.prototype.$common = common;
//全局loading
Vue.prototype.loading = function () {
  const loading = this.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, 0.7)'
  });
  return loading
}
rewirteLog()
Vue.prototype.$md5 = md5;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
