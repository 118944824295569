<template>
  <div class="footerBox">
    <div class="footer" v-if="lsit == 1">
      <div class="footerMsg">
        <div class="footerLeft">
          <div class="footerLogo">
            <p>北京文创动力信息技术有限公司</p>
            <img src="@/assets/image/footerApp.png" alt="">
            <!-- <p>北京文创动力信息技术有限公司</p> -->
            <!-- <p class="time">工作时间:</p>
            <span>周一至周五&nbsp;&nbsp;&nbsp;&nbsp;09:00-18:00</span> -->
          </div>
          <ul class="footerContact">
            <li>服务时间:</li>
            <li>周一至周五&nbsp;&nbsp;&nbsp;&nbsp;09:00-18:00</li>

            <li>公司地址:</li>
            <li>北京市朝阳区华普大厦714室</li>
          </ul>
          <ul class="footerContact">
            <li>咨询热线:</li>
            <li>010-65802309</li>
            <li>收件邮箱:</li>
            <li>service@wespace.cn</li>
            <!-- <li>公司地址:</li>
            <li>北京市朝阳区华普大厦714室</li> -->
          </ul>
        </div>
        <!-- <div class="footerRight">
          <div class="footerImgBox"> -->
            <!-- <img src="" alt="">
            <span>公众号</span> -->
          <!-- </div>
          <div class="footerImgBox">
            <img src="@/assets/image/footerApp.png" alt="">
            <span>时空社APP</span>
          </div>
        </div> -->
      </div>
      <!-- <div class="footerLink">
        <p>友情链接:</p>
        <el-link class="linkItem" :href="item.src" v-for="(item,index) in linkData" :key="index" target="_blank"> {{item.title}}</el-link>
      </div> -->
      <!-- <div class="footerTitle">
        <el-link class="linkItem" :href="item.src" v-for="(item,index) in linkData" :key="index" target="_blank">
          {{item.title}}
        </el-link>
      </div> -->
    </div>
    <div class="fotter1" v-if="lsit == 2">
      <img src="../../assets/footericon.png" alt="">
    </div>
    <div class="footerTitle" v-if="lsit == 1">
      <el-link class="linkItem" :href="item.src" v-for="(item,index) in linkData" :key="index" target="_blank">
        {{item.title}}
      </el-link>
    </div>
    <div class="footerTitle" v-if="lsit == 2">
      <el-link class="linkItem" :href="item.src" v-for="(item,index) in linkDatav" :key="index" target="_blank">
        {{item.title}}
      </el-link>
    </div>
  </div>

</template>

<script>

export default {
  data() {
    return {
      linkData: [
        {
          title: '京公网安备11010502039538号',
          src: 'https://www.beian.gov.cn/portal/recordQuery'
        },
        {
          title: '|',
          // src: ''
        },
        {
          title: 'ICP证京B2-20210005',
          src: 'https://dxzhgl.miit.gov.cn/#/home'
        },
        {
          title: '|',
          // src: ''
        },
        {
          title: '京ICP备11000186号-12 ',
          src: 'https://beian.miit.gov.cn'
        }
      ],
      linkDatav: [
        {
          title: '京ICP备06010807号-3',
          // src: 'https://www.beian.gov.cn/portal/recordQuery'
          src: 'https://beian.miit.gov.cn/'
        },
        {
          title: '|',
          // src: ''
        },
        {
          title: '京公网安备 11010102004511号',
          // src: 'https://dxzhgl.miit.gov.cn/#/home'
          src: 'https://beian.mps.gov.cn/'
        },
        // {
        //   title: '|',
        //   // src: ''
        // },
        // {
        //   title: '京ICP备11000186号-12 ',
        //   src: 'https://beian.miit.gov.cn'
        // }
      ],
      lsit:1
    }
  },
  created(){
    let host = window.location.host
    if (host == 'www.wespace.cn' && host == 'test.wespace.cn') {
      this.lsit = 1
    } else if(host == 'm.ccibs.cn'){
      this.lsit = 2
    }
  },
}
</script>
<style scoped lang="scss">
.footerBox {
  background: #F6F9FF;
  // filter: grayscale(100%);
  //   -webkit-filter: grayscale(100%);
  //   -moz-filter: grayscale(100%);
  //   -ms-filter: grayscale(100%);
  //   -o-filter: grayscale(100%);
  //   filter:progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  //   -webkit-filter: grayscale(1);
  .footer {
    // width: 1200px;
    max-width: 1200px;
    min-width: 1200px;
    margin: 0 auto;
    .footerMsg {
      height: 256px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      display: flex;
      justify-content: space-between;
      .footerLeft {
        // width: 532px;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        .footerLogo {
          width: 400px;
          line-height: 80px;
          // text-align: center;
          img {
            // margin-top: 30px;
            width: 88px;
            height: 88px;
            // margin: 35px 0 12px 80px;
          }
          p {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 500;
            font-weight: bold;
            color: #181818;
          }
          .time {
            margin: 24px 0 10px 0;
          }
          span {
            color: #181818;
          }
        }
        .footerContact {
          text-align: left;
          margin-left: 100px;
          padding-top: 40px;
          width: 400px;
          margin-top: 29px;
          li:nth-of-type(odd) {
            font-size: 14px;
            // font-weight: 600;
            font-weight: bold;
            color: #181818;
            font-weight: 400;
            color: #181818;
            line-height: 19px;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            margin-bottom: 10px;
          }
          li:nth-of-type(even) {
            color: #181818;
            margin-bottom: 20px;
          }
        }
      }
      .footerRight {
        width: 301px;
        display: flex;
        justify-content: space-between;
        .footerImgBox {
          width: 137px;
          margin-top: 44px;
          img {
            width: 137px;
            height: 137px;
          }
          span {
            color: #181818;
            display: block;
            text-align: center;
            margin-top: 15px;
          }
        }
      }
    }
    .footerLink {
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      p {
        font-size: 16px;
        font-weight: bold;
        font-weight: 600;
        color: #181818;
        margin: 29px 0 20px 0;
      }
      .linkItem {
        margin-right: 20px;
        color: #181818;
      }
    }
    // .footerTitle {
    //   width: 100%;
    //   text-align: center;
    //   background-color: #EFF3FC;
    //   .linkItem {
    //     margin-right: 10px;
    //     color: #181818;
    //     font-size: 12px;

    //     line-height: 46px;
    //   }
    // }
    ::v-deep.el-link::after {
      border: none;
    }
  }
  .fotter1{
    width: 100%;
    height: 156px;
    background: #F6F9FF;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      height: 76px;
      width: auto;
    }
  }
}
.footerTitle {
      width: 100%;
      text-align: center;
      background-color: #EFF3FC;
      .linkItem {
        margin-right: 10px;
        color: #181818;
        font-size: 12px;

        line-height: 46px;
      }
    }
</style>
